import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { BaseService } from "./../shared/baseService";
import * as _ from "lodash";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppSettingsService extends BaseService {
  private _settings: any = [];
  constructor(private paramHttpClient: HttpClient) {
    super(paramHttpClient);
  }

  public get baseSettings() {
    return this.CurrentUser ? this.CurrentUser.appSettings : [];
  }

  public get appName() {
    return "CBRE Data Portal";
    // if (!this.baseSettings) {
    //     return "Loading...";
    // }
    // let appSettings = _.filter(this.baseSettings,
    //     function (a: any) { return a.appSettingName == 'APP_NAME'; });
    // return _.head(appSettings) ? _.head(appSettings).appSettingValue : 'Loading...';
  }

  public get cbreAssets() {
    return "CBRE ASSETS";
    if (!this.baseSettings) {
      return "Loading...";
    }
    let appSettings = _.filter(this.baseSettings, function (a: any) {
      return a.appSettingName == "CBRE_ASSETS";
    });
    return _.head(appSettings)
      ? _.head(appSettings).appSettingValue
      : "Loading...";
  }
}
