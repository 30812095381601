import { BaseService } from "./baseService";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class CommonService extends BaseService {
  constructor(private paramHttpClient: HttpClient) {
    super(paramHttpClient);
    // console.log("Inside \"UserService\" constructor");
  }

  private get_okta_access_token_objects() {
    let okta_token_storage_str = sessionStorage.getItem("okta-token-storage");
    if(!okta_token_storage_str){
      return;
    }
    let okta_token_storage = JSON.parse(okta_token_storage_str);
    return okta_token_storage;
  }
  updateTokenRefreshTiming(){
    let sessionStorage1 = this.get_okta_access_token_objects();
    console.log(sessionStorage1);
    let expiry_time = this.get_okta_access_token_objects()['accessToken']['expiresAt'];
    if(!sessionStorage1){
      sessionStorage1['idToken']['expiresAt'] = expiry_time;
    }
    sessionStorage1['idToken']['expiresAt'] = expiry_time;
    sessionStorage.setItem('okta-token-storage', JSON.stringify(sessionStorage1));
  }
}
