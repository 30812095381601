// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-container {
  display: flex;
  flex-direction: column;
}

.example-container > * {
  width: 100%;
}

::ng-deep .btn-primary:hover,
::ng-deep .btn-default:hover {
  background: #d3d8d7;
  border: 1px solid #003f2d;
  color: #003f2d;
}

.button-ok {
  background: #003f2d;
  border: 1px solid #003f2d;
  color: #d3d8d7;
}`, "",{"version":3,"sources":["webpack://./src/tenants/emea/app/common-dialog/common.alert.dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,WAAA;AACF;;AAGE;;EAEE,mBAAA;EAEA,yBAAA;EACA,cAAA;AADJ;;AAYA;EACE,mBAAA;EAEA,yBAAA;EACA,cAAA;AAVF","sourcesContent":[".example-container {\n  display: flex;\n  flex-direction: column;\n}\n\n.example-container > * {\n  width: 100%;\n}\n\n::ng-deep {\n  .btn-primary:hover,\n  .btn-default:hover {\n    background: #d3d8d7;\n   // transition: all 200ms;\n    border: 1px solid #003f2d;\n    color: #003f2d;\n    \n   // text-transform: capitalize;\n  }\n}\n// .button-ok:hover {\n//   background: #cccccc;\n//   color: black;\n//   transition: all 200ms;\n//   border: 1px solid #003f2d;\n// }\n.button-ok {\n  background: #003f2d;\n  //transition: all 200ms;\n  border: 1px solid #003f2d;\n  color: #d3d8d7;\n  //text-transform: capitalize;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
