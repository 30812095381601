import { VERSION } from "./version";

export const environment = {
  production: false,
  envName: "Development",  
  apiUrl: 'https://gia-api-dev.cbre.com',
  tenantId: "62105d2a-48a0-4651-8d84-5a5e197fba03",
  tenant: "cbrelabsEXT.onmicrosoft.com",
  instance: "https://cbrelabsEXT.b2clogin.com/",
  clientId: "42cf6fc9-1f57-4a25-a97f-beec8a3fada3",   
  authnPolicy: "b2c_1a_giadataportal__signup_signin",
  pwdResetPolicy: "B2C_1A_GIADATAPORTAL_PASSWORDRESET",
  minutesUntilAutoLogout: 240,
  appVersion: VERSION.version
};