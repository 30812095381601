import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard.component';
import { dashboardRouter } from './dashboard.router';

@NgModule({
    imports: [
        dashboardRouter
    ],
    declarations: [
        DashboardComponent
    ],
    exports: [DashboardComponent]
})
export class DashboardModule { }
