import { BaseService } from "./baseService";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class UserService extends BaseService {
  constructor(private paramHttpClient: HttpClient) {
    super(paramHttpClient);
    // console.log("Inside \"UserService\" constructor");
  }

  getClientDetails(): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/ClientNReportTypes/GetClientNReportTypes`;
    return this.getAndObserve(targetURL);
  }

  getSPDetails(id: number): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/UserInformation/GetServiceProviderIdsByUserId?UserNumberId=${id}`;
    return this.getAndObserve(targetURL);
  }

  getUserByLoginId(
    userLoginId: string,
    client_id: string = ""
  ): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/UserAuthorization?client_id=${client_id}`;
    return this.getAndObserve(targetURL);
  }

  getReportTypeScheduleClients(): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/Reports/ReportTypeScheduleClients`;
    return this.getAndObserve(targetURL);
  }

  getUserTypeReportingSchedule(userLoginId: string): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/UserInformation/UserTypeReportingSchedule?userLoginId=${userLoginId}`;
    return this.getAndObserve(targetURL);
  }
  logoutUser(): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/UserAuthorization/LogoutUser`;
    return this.postAndObserve(targetURL, null);
  }
  getServiceProviderDetails(): Observable<any> {
    const targetURL = `${environment.apiUrl}/api/ServiceProviderNUsers/SPsList`;
    return this.getAndObserve(targetURL);
  }
}
