import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { Router } from "@angular/router";

export interface ConfirmationDialogData {
  title: string;
  description: string;
  isCancelClick: boolean;
  isOkClick: boolean;
  dialogData: any;
}

@Component({
  selector: "common-confirmation-dialog",
  styleUrls: ["./common.confirmation.dialog.component.scss"],
  templateUrl: "./common.confirmation.dialog.component.html",
  standalone: true,
  imports: [MatDialogModule, MatInputModule, MatIconModule, MatButtonModule],
})
export class CommonConfirmationDialog {
  yesButtonText = "YES";
  noButtonText = "CANCEL";
  timeRemainingMessage = "";
  constructor(
    private dialogRef: MatDialogRef<CommonConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    private router: Router,
    public ref: ChangeDetectorRef
  ) {
    this.router.events.subscribe((val) => {
      if (!this.dialogRef) return;
      this.dialogRef.close();
    });
    if (this.data.dialogData == "InactivityLogout") {
      let timeLeft = 60;
      this.yesButtonText = "KEEP ME LOGGED IN";
      this.noButtonText = "LOG ME OUT";
      this.timeRemainingMessage = "Time Remaining: " + timeLeft + " Second";
      setInterval(() => {
        if (timeLeft > 0) {
          timeLeft--;
          this.timeRemainingMessage = "Time Remaining: " + timeLeft + " Second";
        } else {
          this.onNoClick();
        }
      }, 1000);
    }
  }

  ngOnInit() {
    console.log(" ");
  }

  private setOkCancelStatus(isOkClicked: boolean) {
    this.data.isOkClick = isOkClicked;
    this.data.isCancelClick = !isOkClicked;
  }

  onNoClick(): void {
    this.setOkCancelStatus(false);
    this.dialogRef.close(this.data);
  }

  onOkClick(): void {
    this.setOkCancelStatus(true);
    this.dialogRef.close(this.data);
  }

  /*

    We can then use the dialog reference to subscribe to the afterClosed()
    observable, which will emit a value containing the output data passed to
    dialogRef.close().

    dialogRef.afterClosed().subscribe(
        data => console.log('Dialog output:', data)
    );
    */
}
