import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LogConsole } from "./app-console-logger";
import { LogLocalStorage } from "./app-localstorage-logger";
import { LogPublisher } from "./app-log-publishers";
import { LogWebApi } from "./app-api-logger";
import { Observable, throwError } from "rxjs";

const PUBLISHERS_FILE = "./log-publishers.json";

@Injectable({
  providedIn: "root",
})
export class LogPublishersService {
  settings: any = [
    {
      loggerName: "console",
      loggerLocation: "",
      isActive: false,
    },
    {
      loggerName: "localstorage",
      loggerLocation: "gia.data.agg.platform.portal.logs",
      isActive: true,
    },
    {
      loggerName: "webapi",
      loggerLocation: "/api/UILogger/Log",
      isActive: true,
    },
  ];
  constructor(private http: HttpClient) {
    // Build publishers arrays
    this.buildPublishers();
  }
  // getLoggers(): Observable<LogPublisherConfig[]> {
  //   return (
  //     this.http
  //       .get(PUBLISHERS_FILE)
  //       // .map((response) => response)
  //       .catch(this.handleErrors)
  //   );
  // }
  private handleErrors(error: any): Observable<any> {
    let errors: string[] = [];
    let msg: string = "";

    msg = "Status: " + error.status;
    msg += " - Status Text: " + error.statusText;
    if (error.json()) {
      msg += " - Exception Message: " + error.json().exceptionMessage;
    }
    errors.push(msg);
    console.error("An error occurred", errors);
    return throwError(errors);
  }

  // Public properties
  publishers: LogPublisher[] = [];

  // Build publishers array
  buildPublishers(): void {
    let logPub: LogPublisher;
    if (!this.settings) {
      logPub = new LogConsole();
      // Add publisher to array
      this.publishers.push(logPub);
    } else {
      for (let pub of this.settings.filter((p) => p.isActive)) {
        switch (pub.loggerName.toLowerCase()) {
          case "console":
            logPub = new LogConsole();

            break;
          case "localstorage":
            logPub = new LogLocalStorage(this.http);

            break;
          case "webapi":
            logPub = new LogWebApi(this.http);

            break;
          default:
            logPub = new LogConsole();

            break;
        }

        // Set location of logging
        logPub.location = pub.loggerLocation;

        // Add publisher to array
        this.publishers.push(logPub);
      }
    }
    // this.getLoggers().subscribe((response) => {
    //   for (let pub of response.filter((p) => p.isActive)) {
    //     switch (pub.loggerName.toLowerCase()) {
    //       case "console":
    //         logPub = new LogConsole();
    //         break;
    //       case "localstorage":
    //         logPub = new LogLocalStorage();
    //         break;
    //       case "webapi":
    //         logPub = new LogWebApi(this.http);
    //         break;
    //     }

    //     // Set location of logging
    //     logPub.location = pub.loggerLocation;

    //     // Add publisher to array
    //     this.publishers.push(logPub);
    //   }
    // });
  }

  //   buildPublishers(): void {
  //     // Create instance of LogConsole Class
  //     this.publishers.push(new LogConsole());

  //     // Create instance of `LogLocalStorage` Class
  //     this.publishers.push(new LogLocalStorage());
  //   }
}
