import { VERSION } from "./version";

export const environment = {
  production: false,
  envName: "Development",  
  apiUrl: 'https://gia-api-dev.cbre.com',
  issuer: "https://login-uat.cbre.com/oauth2/default",
  clientId: "0oa22z632d933XNYm0h8",
  redirectUri: window.location.origin + '/',
  minutesUntilAutoLogout: 240,
  appVersion: VERSION.version
};