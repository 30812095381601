import { Observable } from "rxjs";
import { of } from "rxjs";
// import "rxjs/add/observable/of";
// import "rxjs/add/operator/map";
// import "rxjs/add/operator/catch";
// import "rxjs/add/observable/throw";
import { LogEntry } from "./app-log.service";
import { LogPublisher } from "./app-log-publishers";
import * as _ from "lodash";

export class LogConsole extends LogPublisher {
  log(entry: LogEntry): Observable<boolean> {
    let is_log_enabled = false;
    let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    if (currentUser) {
      let settings_log_enabled = _.filter(
        currentUser.appSettings,
        function (e) {
          return e.appSettingName == "IS_CONSOLE_LOG_ENABLED";
        }
      );
      let setting_log_enabled = _.first(settings_log_enabled);
      if (setting_log_enabled) {
        if (setting_log_enabled.appSettingValue == "True") {
          is_log_enabled = true;
        }
      }
    }
    if (is_log_enabled) {
      console.log(entry.buildLogString());
      return of(true);
    } else {
      return of(true);
    }
  }

  clear(): Observable<boolean> {
    console.clear();
    return of(true);
  }
}
