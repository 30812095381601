import { Component } from '@angular/core';
import { AuthService } from '../shared/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  constructor(public auth: AuthService) {
  }
  getUserHint() {
    let firstName = this.auth?.CurrentUser?.firstName || ".";
    let lastName = this.auth?.CurrentUser?.lastName || ".";
    return firstName[0] + lastName[0];
  }
}
