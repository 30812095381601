import { Observable } from "rxjs";
import { of } from "rxjs";
import { throwError } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LogEntry } from "./app-log.service";
import { LogPublisher } from "./app-log-publishers";
import * as _ from "lodash";
import { environment } from "../../../environments/environment";

export class LogWebApi extends LogPublisher {
  constructor(private http: HttpClient) {
    // Must call `super()`from derived classes
    super();
  }

  // Add log entry to back end data store
  log(entry: LogEntry): Observable<boolean> {
    let is_log_enabled = false;
    let _location_web_api = this.location;
    let currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    if (currentUser) {
      let settings_log_enabled = _.filter(
        currentUser.appSettings,
        function (e) {
          return e.appSettingName == "IS_WEB_API_LOG_ENABLED";
        }
      );
      let setting_log_enabled = _.first(settings_log_enabled);
      if (setting_log_enabled) {
        if (setting_log_enabled.appSettingValue == "True") {
          is_log_enabled = true;
        }
      }
      let settings_log_web_api_url = _.filter(
        currentUser.appSettings,
        function (e) {
          return e.appSettingName == "WEB_API_LOG_WRITER_URL";
        }
      );
      let setting_log_web_api_url = _.first(settings_log_web_api_url);
      if (setting_log_web_api_url) {
        _location_web_api = !setting_log_web_api_url.appSettingValue
          ? this.location
          : setting_log_web_api_url.appSettingValue;
      }
    }
    if (is_log_enabled) {
      let _location = `${environment.apiUrl}${_location_web_api}`;
      return this.http
        .post(_location, entry, {
          headers: new HttpHeaders({
            authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
          }),
          observe: "response",
        })
        .catch(this.handleErrors);
      // .catch(this.handleErrors);
      // .catch(this.handleErrors);
    } else {
      return of(true);
    }
  }

  // Clear all log entries from local storage
  clear(): Observable<boolean> {
    // TODO: Call Web API to clear all values
    return of(true);
  }

  private handleErrors(error: any): Observable<any> {
    let errors: string[] = [];
    let msg: string = "";

    msg = "Status: " + error.status;
    msg += " - Status Text: " + error.statusText;
    if (error.json()) {
      msg += " - Exception Message: " + error.json().exceptionMessage;
    }
    errors.push(msg);

    console.error("An error occurred", errors);
    return throwError(errors);
  }
}
