import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HomeComponent } from "./home.component";

@NgModule({
    imports: [RouterModule, CommonModule],
    declarations: [HomeComponent],
    providers: [],
    exports: [HomeComponent],
  })
  export class HomeModule {}