import { Observable, of } from "rxjs";
// import "rxjs/add/observable/of";
import { Injectable } from "@angular/core";
// import "rxjs/add/operator/map";
// import "rxjs/add/operator/catch";
// import "rxjs/add/observable/throw";
import { LogEntry } from "./app-log.service";

export abstract class LogPublisher {
  loggerName: string;
  loggerLocation: string;
  location: string;
  isActive: boolean;
  abstract log(record: LogEntry): Observable<boolean>;
  abstract clear(): Observable<boolean>;
}

@Injectable()
export class LogPublishersService {
  constructor() {
    // Build publishers arrays
    this.buildPublishers();
  }

  // Public properties
  publishers: LogPublisher[] = [];

  // Build publishers array
  buildPublishers(): void {
    // Create instance of LogConsole Class
    //this.publishers.push(new LogConsole());
  }
}
