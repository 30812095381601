// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  height: 36px;
  background: #f5f7f7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #636363;
  font-size: 14px;
  font-family: Calibre, "Helvetica Neue", sans-serif;
  padding: 0 16px;
}

footer > .info {
  display: flex;
  align-items: center;
}

footer > .info > div {
  padding: 4px 8px 0;
  display: flex;
  align-items: center;
  height: 16px;
}

footer > .info > div:not(:last-child) {
  border-right: 2px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/tenants/emea/app/footer/footer.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,cAAc;EACd,eAAe;EACf,kDAAkD;EAClD,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,4BAA4B;AAC9B","sourcesContent":["footer {\n  height: 36px;\n  background: #f5f7f7;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  color: #636363;\n  font-size: 14px;\n  font-family: Calibre, \"Helvetica Neue\", sans-serif;\n  padding: 0 16px;\n}\n\nfooter > .info {\n  display: flex;\n  align-items: center;\n}\n\nfooter > .info > div {\n  padding: 4px 8px 0;\n  display: flex;\n  align-items: center;\n  height: 16px;\n}\n\nfooter > .info > div:not(:last-child) {\n  border-right: 2px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
