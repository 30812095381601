import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from "@angular/common";
import { ChildRoute, LeftNavComponent } from "../left-nav/left-nav.component";
import { Router } from '@angular/router';

@Component({
  selector: 'app-nav-link',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './nav-link.component.html',
  styleUrls: ['./nav-link.component.scss']
})
export class NavLinkComponent {
  @ViewChild('toggleButton') toggleButton: ElementRef | undefined;
  @ViewChild('menu') menu: ElementRef | undefined;
  @Input() routeName: string | undefined;
  @Input() routeIcon: string | undefined;
  @Input() routeURL: string | undefined;
  @Input() childRoutes: ChildRoute[] | undefined;
  @Input() menuCollapsed: boolean | undefined;
  @Input() routeEmpty: boolean | undefined;
  dropdownActive: boolean = false;
  collapsedHovered: boolean = false;


  toggleNavDropdown(): void {
    this.dropdownActive = !this.dropdownActive;
    if (!this.menuCollapsed) {
      // this.dropdownActive = !this.dropdownActive;
    } else if (this.menuCollapsed) {
      this.collapsedHovered = !this.collapsedHovered
    }
   
  }
  routeLink(name) {
    if (this.childRoutes == undefined && !this.dropdownActive) {
      this.route.navigateByUrl(this.routeURL);
    }
    if(this.routeName == "Self Service Hub"){
      this.leftNav.navCollapsed = !this.leftNav.navCollapsed;
    }
  }
  routeSubRouteLink(url: any) {
    this.route.navigateByUrl(url);
  }
  constructor(private route: Router, private renderer: Renderer2, private leftNav: LeftNavComponent) {
    console.log(this.routeName, this.routeEmpty);

    this.renderer.listen('window', 'click', (event: Event) => {
      if (this.menu) { // Check if menu reference exists
        const clickedInside = this.menu.nativeElement.contains(event.target);
        if (!clickedInside) {
          this.collapsedHovered = false;
        }
      }
    });
  }
}
