import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthService } from "./shared/auth.service";
import { UserService } from "./shared/user.service";

import { CommonUtilityService } from "./shared/common.utility.service";
import { Adal6Service } from "adal-angular6";
import { LogService } from "./shared/app-loggers/app-log.service";
import { ToastrModule } from "ngx-toastr";
import { HomeModule } from "./home/home.module";
import { HeaderModule } from "./header/header.module";
import { FooterModule } from "./footer/footer.module";
import { DashboardModule } from "./dashboard/dashboard.module";
import { MAT_DIALOG_DEFAULT_OPTIONS } from "@angular/material/dialog";
import { CommonModule } from "@angular/common";
import { HttpClientInterceptor } from "./shared/httpclient.interceptor";
import { ErrorDialogService } from "./shared/error-dialog.service";
import { AppMaterialModule } from "./app.material.module";
import { FormsModule } from "@angular/forms";
import { LeftNavComponent } from "./left-nav/left-nav.component";
import { NgxSpinnerModule } from "ngx-spinner";
// import { EmeraldDesignSystemModule } from "@emerald-angular/design-system";
@NgModule({
  declarations: [AppComponent],
  imports: [
    // EmeraldDesignSystemModule,
    BrowserModule,
    AppMaterialModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HomeModule,
    HeaderModule,
    FooterModule,
    DashboardModule,
    ToastrModule.forRoot({
      timeOut: 3500,
      preventDuplicates: true,
      enableHtml: true,
      closeButton: true,
      progressBar: true,
      positionClass: "apbpositionClass",

    }),
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LeftNavComponent,
    NgxSpinnerModule
    
  ],
  providers: [
    AuthService,
    UserService,
  
    CommonUtilityService,
    Adal6Service,
    LogService,
    ErrorDialogService,
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpClientInterceptor,
        multi: true,
      },
      {
        provide: MAT_DIALOG_DEFAULT_OPTIONS,
        useValue: {
          hasBackdrop: true,
          disableClose: true,
          closeOnNavigation: true,
          panelClass: "apbPanelClass",
        },
      },
    ],
  ],
  // MsalRedirectComponent
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
