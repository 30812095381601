import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
  CommonAlertDialog,
  AlertDialogData,
} from "../common-dialog/common.alert.dialog.component";
import { Adal6Service } from "adal-angular6";
import { NgxSpinnerService } from "ngx-spinner";


@Injectable()
export class ErrorDialogService {
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CommonAlertDialog>,
    private adalService: Adal6Service,
    private spinner:NgxSpinnerService
   
  ) {}

  openDialog(error: any): void {
    this.spinner.hide();
    setTimeout(() => {
      const dialogRef = this.dialog.open(CommonAlertDialog, {
        data: this.getQualifiedErrorMessage(error),
      });

      dialogRef.afterClosed().subscribe((error: any) => {
        if (error.status == 401) this.adalService.login();
      });
    });
  }

  private getQualifiedErrorMessage(error: any) {
    if (!error) return;
    if (!error.status) error.status = 0;
    let data = {
      reason:
        error.status == 0
          ? error.message
          : error && error.error
          ? error.error.reason
          : error.statusText,
      status: error.status,
    };
    var msg = <AlertDialogData>{ title: "", description: "" };
    switch (data.status) {
      case 400:
        msg = <AlertDialogData>{
          title: "Bad Request !",
          description:
            "The request could not be understood by the server due to malformed syntax..",
          dialogData: data,
        };
        break;
      case 401:
        msg = <AlertDialogData>{
          title: "Session Expired !",
          // title: 'Unauthorized !',
          description: " You have been logged out. Please log in again.",
          // description: 'You do not have permission to view this page. <br/> If message was received in error please refresh and confirm login status. <br/> If error continues contact your system administrator.',
          dialogData: data,
        };
        break;
      case 403:
        msg = <AlertDialogData>{
          title: "Forbidden !",
          description:
            "The server understood the request, but is refusing to fulfill it.",
          dialogData: data,
        };
        break;
      case 404:
        msg = <AlertDialogData>{
          title: "Not Found !",
          description:
            "The server has not found anything matching the Request-URI.",
          dialogData: data,
        };
        break;
      case 405:
        msg = <AlertDialogData>{
          title: "Method Not Allowed !",
          description:
            "The method specified in the Request-Line is not allowed for the resource identified by the Request-URI.",
          dialogData: data,
        };
        break;
      case 0:
        msg = <AlertDialogData>{
          title: "Oops! Something went wrong.",
          description: `The application has encountered an unknown error.
                    <br/> If error continues contact your system administrator.`,
          dialogData: data,
        };
        break;
      case -1:
        msg = <AlertDialogData>{
          title: "User session timeout.",
          description: `The application user session has timeout.
                        <br/> Start new session and try again.`,
          dialogData: data,
        };
        break;
      default:
        msg = <AlertDialogData>{
          title: data.reason,
          description: data.status,
          dialogData: data,
        };
    }
    return msg;
  }
}
