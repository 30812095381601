import { Component } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-footer',
  // standalone: true,
  // imports: [],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {
  appVersion: string ;
  version = "";
  constructor() {
   this.version = environment.appVersion;
    
}
}
