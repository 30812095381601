import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpEvent,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { AuthzUserInfo } from "../models/AuthzUserInfo.model";
import { Adal6Service } from "adal-angular6";
import { environment } from "../../environments/environment";
// import { environment } from "src/environments/environment";
import { throwError } from 'rxjs';


export class BaseService {
  options: Object;
  serviceBaseUrl: string;
  azureAdApiBaseUrl: string;
  private currentUser: AuthzUserInfo;
  private httpClient: HttpClient;
  public adalService: Adal6Service;

  constructor(paramHttpClient: HttpClient = null) {
    console.log("Inside BaseService constructor");

    this.httpClient = paramHttpClient;
  }

  downloadFileAsBlob(
    url: string,
    fileSecurityToken: any
  ): Observable<HttpEvent<Blob>> {
    console.log("Inside get<T>(url: string): " + url);
    return this.httpClient.request(
      new HttpRequest(
        "POST",
        url,
        { fileSecurityToken: fileSecurityToken },
        {
          headers: new HttpHeaders({
            authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
          }),
          reportProgress: true,
          responseType: "blob",
        }
      )
    );
  }

  downloadFileAsBlobPost(
    url: string
  ): Observable<HttpEvent<Blob>> {
    console.log("Inside get<T>(url: string): " + url);
    return this.httpClient.request(
      new HttpRequest(
        "POST",
        url,
        {},
        {
          headers: new HttpHeaders({
            authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
          }),
          reportProgress: true,
          responseType: "blob",
        }
      )
    );
  }


  downloadFileAsBlobGet(url: string): Observable<HttpEvent<Blob>> {
    console.log("Inside get<T>(url: string): " + url);
    return this.httpClient.request(
      new HttpRequest(
        "GET",
        url,
        {},
        {
          headers: new HttpHeaders({
            authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
          }),
          reportProgress: true,
          responseType: "blob",
        }
      )
    );
  }

  downloadFileAsBlobNoAuth(url: string): Observable<any> {
    console.log("Inside get<T>(url: string): " + url);
    return this.httpClient.post(
      url,
      {},
      {
        headers: new HttpHeaders({
          authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
        }),
        reportProgress: true,
        responseType: "blob" as "json",
      }
    );
  }

  getAndObserve<T>(url: string): Observable<T> {
    console.log("Inside getAndObserve<T>(url: string): " + url);

    return this.httpClient
      .get<T>(url, { observe: "response" })
      .pipe(catchError(this.handleError2(url, [])));
  }
  getReqAndObserve<T>(url: string, _params: HttpParams): Observable<T> {
    console.log("Inside getReqAndObserve<T>(url: string): " + url);
    return this.httpClient
      .get<T>(url, { observe: "response", params: _params })
      .pipe(catchError(this.handleError2(url, [])));
  }
  postAndObserve<T>(url: string, data: any): Observable<T> {
    console.log("Inside postAndObserve<T>(url: string, data: any): " + url);

    return this.httpClient
      .post<T>(url, data, { observe: "response" })
      .pipe(catchError(this.handleError2(url, [])));
  }

  putAndObserve<T>(url: string, data: any): Observable<T> {
    console.log("Inside putAndObserve<T>(url: string, data: any): " + url);
    console.log(data, ": Data");

    return this.httpClient
      .put<T>(url, data, { observe: "response" })
      .pipe(catchError(this.handleError2(url, [])));
  }

  deleteAndObserve<T>(url: string): Observable<T> {
    console.log("Inside putAndObserve<T>(url: string, data: any): " + url);
    return this.httpClient
      .delete<T>(url)
      .pipe(catchError(this.handleError2(url, [])));
  }

  get<T>(url: string): Observable<T> {
    console.log("Inside get<T>(url: string): " + url);
    const httpOptions = {
      headers: new HttpHeaders({
        authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
      }),
    };

    return this.httpClient
      .get<T>(url, httpOptions)
      .pipe(catchError(this.handleError2(url, [])));
  }

  post<T>(url: string, data: any): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        authorization: `Bearer ${sessionStorage.getItem("adal.idtoken")}`,
        "Access-Control-Allow-Origin": `${environment.apiUrl}`,
        "Access-Control-Allow-Methods": "OPTIONS,GET,POST,PUT,DELETE",
        "Access-Control-Allow-Headers":
          "*,Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control",
      }),
    };
    return this.httpClient
      .post<T>(url, data, httpOptions)
      .pipe(catchError(this.handleError2(url, [])));
  }

  put<T>(url: string, data: any): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": `${environment.apiUrl}`,
        "Access-Control-Allow-Methods": "OPTIONS,GET,POST,PUT,DELETE",
        "Access-Control-Allow-Headers":
          "*,Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control",
      }),
    };

    return this.httpClient
      .put<T>(url, data, httpOptions)
      .pipe(catchError(this.handleError2()));
  }

  delete<T>(url): Observable<T> {
    const httpOptions = {
      headers: new HttpHeaders({
        "Access-Control-Allow-Origin": `${environment.apiUrl}`,
        "Access-Control-Allow-Methods": "OPTIONS,GET,POST,PUT,DELETE",
        "Access-Control-Allow-Headers":
          "*,Content-Type, Depth, User-Agent, X-File-Size, X-Requested-With, If-Modified-Since, X-File-Name, Cache-Control",
      }),
    };

    return this.httpClient
      .delete(url, httpOptions)
      .pipe(catchError(this.handleError2(url, [])));
  }

  protected handleError1(error: HttpErrorResponse) {
    return Observable.throw(error.message);
  }

  protected handleError2<T>(operation = "operation", result?: T) {
    return (error: any): Observable<any> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);
      // return Observable.throw(error);
      return throwError(error);
      // Let the app keep running by returning an empty result.
      // return of(result as T);
    };
  }

  public get CurrentUser(): AuthzUserInfo {
    if (
      sessionStorage.getItem("CurrentUser") !== undefined &&
      sessionStorage.getItem("CurrentUser")
    ) {
      this.currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
    }
    return this.currentUser;
  }

  public set CurrentUser(user: AuthzUserInfo) {
    this.currentUser = user;
    sessionStorage.setItem("CurrentUser", JSON.stringify(user));
  }

  public get CurrentUserLoginId(): string {
    return this.CurrentUser.userLoginId;
  }

  public set ForgotPasswordUsed(isUsed: boolean) {
    sessionStorage.setItem(
      "ForgotPasswordUsed",
      isUsed === true ? "true" : "false"
    );
  }

  public get ForgotPasswordUsed(): boolean {
    return sessionStorage.getItem("ForgotPasswordUsed") === "true";
  }
}
