import { Component } from "@angular/core";
import * as moment from "moment"; // add this 1 of 4
@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
  public newDate: string;
  constructor() {
    const now = moment(); // add this 2 of 4
    this.newDate = moment(now).format("DD MMM YYYY");
  }
}
